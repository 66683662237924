import { colorBlueMedium } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { useRouter } from "next/router";

import Anchor from "../../Anchor";
import Text from "../../Text";

interface Props {
  blocks: {
    includeInNav?: boolean;
    index: number;
    navTitle?: string;
    slug: string;
    type?: string;
  }[];
  className?: string;
}

const SubnavList = ({ className, blocks }: Props) => {
  const router = useRouter();
  return (
    <ul
      className={className}
      css={css`
        align-items: center;
        display: flex;
        gap: 4rem;
        height: inherit;
        margin: 0;
        padding: 0;
      `}
    >
      {blocks
        .filter((block) => block.includeInNav)
        .map((block) => {
          const { navTitle, slug } = block;

          /**
           * Passing anchors to next/link will result in errors.
           * Wrong: href={"#an-anchor"}
           *
           * You have to pass the full path:
           * Right: href={"/products/single-cell-gene-expression#an-anchor"}
           */
          const [basePath] = router.asPath.split("#");
          const href = [basePath, "#", slug].join("");

          return (
            <li
              css={css`
                list-style: none;
              `}
              key={slug}
            >
              <Text
                as={"span"}
                color={"midgray"}
                css={css`
                  white-space: nowrap;
                `}
                key={slug}
                size={"medium"}
              >
                <Anchor
                  aria-label={`scroll to ${navTitle} section`}
                  color={"inherit"}
                  hoverColor={colorBlueMedium}
                  href={href}
                  underlineOnHover={false}
                >
                  {navTitle}
                </Anchor>
              </Text>
            </li>
          );
        })}
    </ul>
  );
};

export default SubnavList;
