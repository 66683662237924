import type { ReactNode } from "react";
import { createElement } from "react";

import { Components } from "./constants";
import type { Component } from "./types";

export const createCarousel = (data?: Component): ReactNode => {
  if (!data) return null;

  const createComponent = (item: Component): ReactNode => {
    const { data, type } = item;
    return createElement(Components[type] as any, {
      ...data,
    });
  };

  return createComponent(data);
};
