import { mediaTabletPortrait } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import type { Action } from "../../CTAButton";
import CTAButton from "../../CTAButton";
import Heading from "../../Heading";

interface Props {
  actions?: Action[];
  title?: string;
}

const CTA: FunctionComponent<Props> = ({ actions, title }) => {
  return (
    <div
      className={"CTA"}
      css={css`
        margin: 0 auto;
        max-width: 1200px;
        padding: 6rem 0;
        width: 100%;
      `}
    >
      {title ? (
        <div
          css={css`
            padding: 0 10%;
            text-align: center;

            @media (max-width: ${mediaTabletPortrait}) {
              margin-bottom: 4rem;
              padding: 0 5%;
            }
          `}
        >
          <Heading
            as={"h3"}
            color={"white"}
            css={css`
              margin-bottom: 2rem;
            `}
            responsive={true}
            size={"xxlarge"}
          >
            {title}
          </Heading>
        </div>
      ) : null}

      {actions ? (
        <div
          css={css`
            display: flex;
            gap: 2rem;
            justify-content: center;

            @media (max-width: ${mediaTabletPortrait}) {
              align-items: center;
              flex-direction: column;
              gap: 2rem;
            }
          `}
        >
          {actions.map((action) => {
            return <CTAButton action={action} key={action.url} mode={"dark"} />;
          })}
        </div>
      ) : null}
    </div>
  );
};

export default CTA;
