import { colorSteelDarkest, mediaTabletLandscape } from "@10xdev/design-tokens";
import { css, keyframes } from "@emotion/react";
import { useRouter } from "next/router";
import type { FunctionComponent } from "react";
import { useEffect, useState } from "react";

import Anchor from "../../Anchor";
import Button from "../../Button";
import Text from "../../Text";

interface Props {
  action: {
    label?: string;
    target: boolean;
    url: string;
  };
  links: Array<{
    anchor: string;
    label: string;
    stickyHeaderLabel?: string;
  }>;
}

const revealAnimation = keyframes`
  from {
    background-color: rgba(0, 0, 0, 0.1);
  }
  to {
    background-color: rgba(0, 0, 0, 0);
  }
`;

const StickyHeader: FunctionComponent<Props> = ({ action, links }) => {
  const router = useRouter();

  const [isVisible, setIsVisible] = useState(false);
  const header: HTMLElement | undefined =
    typeof document !== "undefined"
      ? Array.from(document.getElementsByTagName("header")).pop()
      : undefined;

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setIsVisible(!entry.isIntersecting));
    });
    header && observer.observe(header);
    return () => observer.disconnect();
  }, [header]);

  const handleClick = (url: string) => router.push(url);

  return isVisible ? (
    <nav
      className={"SttickyHeader"}
      css={css`
        align-items: center;
        animation: ${revealAnimation} 0.25s;
        background: ${colorSteelDarkest};
        display: flex;
        height: 4rem;
        justify-content: space-between;
        left: 0;
        padding: 0 2rem;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 2;
      `}
    >
      <div
        css={css`
          align-items: center;
          display: flex;
          justify-content: flex-start;
        `}
      >
        <Text as={"h1"} color={"white"} size={"medium"} weight={"semibold"}>
          <Anchor
            color={"inherit"}
            href={{
              hash: links[0].anchor,
              pathname: router.pathname,
              query: router.query,
            }}
            underlineOnHover={true}
          >
            {links[0].stickyHeaderLabel}
          </Anchor>
        </Text>
      </div>

      <div
        css={css`
          align-items: center;
          display: flex;
          justify-content: flex-end;
        `}
      >
        <ul
          css={css`
            display: flex;
            margin: 0;
            padding: 0;

            @media (max-width: ${mediaTabletLandscape}) {
              display: none;
            }
          `}
        >
          {links.slice(1).map((item) => {
            const { anchor, label } = item;
            return (
              <Text
                as={"li"}
                color={"gray"}
                css={css`
                  list-style: none;
                  margin-right: 2rem;
                `}
                key={label}
                size={"small"}
              >
                <Anchor
                  color={"inherit"}
                  hoverColor={"white"}
                  href={{
                    hash: anchor,
                    pathname: router.pathname,
                    query: router.query,
                  }}
                  id={anchor + "-sticky-header"}
                >
                  {label}
                </Anchor>
              </Text>
            );
          })}
        </ul>

        {/* The wrapper prevents the button from sizing strangely */}
        <div>
          <Button
            /* This class name is for Google tracking. Do not change plz. */
            className={"cta-primary"}
            css={{
              height: "2rem",
            }}
            /* This ID is for Google tracking. Do not change plz. */
            id={"cta-primary-sticky-header"}
            onClick={() => handleClick(action.url)}
          >
            <Text
              as={"span"}
              color={"white"}
              css={css`
                padding-left: 1rem;
                padding-right: 1rem;
              `}
              size={"xsmall"}
              weight={"semibold"}
            >
              {action.label}
            </Text>
          </Button>
        </div>
      </div>
    </nav>
  ) : null;
};

export default StickyHeader;
