import BlogCard from "./CarouselCards/BlogCard";
import ImageCard from "./CarouselCards/ImageCard";
import PanelCard from "./CarouselCards/PanelCard";
import SCAssayCard from "./CarouselCards/SCAssayCard";

export const Components = {
  BlogCard,
  ImageCard,
  PanelCard,
  SCAssayCard,
};
