import type { LinkItem } from "@10x/cms/types";
import {
  borderRadiusMedium,
  borderStandard,
  colorBlueDark,
  colorGrayLightest,
  colorWhite,
  layoutWidth980,
  mediaTabletPortrait,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Button from "../../Button";
import Heading from "../../Heading";
import Icon from "../../Icon";
import Image from "../../Image";
import Text from "../../Text";
import type { ButtonType, ImageType } from "./types";

interface Props {
  button?: ButtonType;
  description?: string;
  image?: ImageType;
  link?: LinkItem;
  title?: string;
}

const Summary: FunctionComponent<Props> = ({
  button,
  description,
  image,
  link,
  title,
}) => {
  const { icon, label, type, url } = button || {};
  return (
    <div
      css={css`
        background: linear-gradient(
          180deg,
          ${colorWhite} 0%,
          ${colorGrayLightest} 100%
        );
        border: ${borderStandard};
        border-radius: ${borderRadiusMedium};
        margin-bottom: 4rem;

        @media (min-width: ${mediaTabletPortrait}) {
          display: flex;
        }

        @media (min-width: ${layoutWidth980}) {
          align-items: center;
          border: ${borderStandard};
          border-radius: ${borderRadiusMedium};
          display: flex;
          margin-bottom: 4rem;
        }
      `}
    >
      <div
        css={css`
          flex-shrink: 0;
          margin-bottom: 2rem;
          width: 80%;

          @media (min-width: ${mediaTabletPortrait}) {
            align-self: flex-end;
            border-radius: 0 0 0 6px;
            margin-bottom: 0;
            margin-right: 3rem;
            max-width: 264px;
            overflow: hidden;
          }

          @media (min-width: ${layoutWidth980}) {
            margin-bottom: 0;
          }
        `}
      >
        {image?.url && (
          <Image alt={image.altText} src={image.url} width={"100%"} />
        )}
      </div>

      <div
        css={css`
          padding: 0 2rem 2rem;

          @media (min-width: ${mediaTabletPortrait}) {
            padding: 2rem 2rem 2rem 0;
            padding-bottom: 0;
          }

          @media (min-width: ${layoutWidth980}) {
            align-items: center;
            display: flex;
            padding: 2rem 4rem 2rem 0;
          }
        `}
      >
        <div
          css={css`
            margin-bottom: 2rem;

            @media (min-width: ${layoutWidth980}) {
              margin: 0 3.5rem 0 0;
              max-width: 520px;
            }
          `}
        >
          <Heading
            as={"h4"}
            css={css`
              margin-bottom: 0.5rem;
            `}
            size={"large"}
          >
            {title}
          </Heading>
          <Text
            as={"div"}
            color={"midgray"}
            css={css`
              margin-bottom: ${link ? "1rem" : 0};
            `}
            size={"medium"}
          >
            {description}
          </Text>

          {link?.url ? (
            <Text as={"span"} color={"blue"} size={"small"} weight={"semibold"}>
              <Anchor
                color={"inherit"}
                hoverColor={colorBlueDark}
                href={link.url}
                target={link.target}
              >
                {link.label}
                {link.type ? (
                  <Icon
                    size={"12px"}
                    source={link.type}
                    xPos={"16px"}
                    yPos={"1px"}
                  />
                ) : null}
              </Anchor>
            </Text>
          ) : null}
        </div>

        <div
          css={css`
            flex-shrink: 0;
          `}
        >
          {label ? (
            <Button
              background={type === "primary" ? "blue" : "white"}
              border={type === "primary" ? false : true}
              color={type === "primary" ? "white" : "blue"}
              css={{
                width: "240px",
              }}
              onClick={() => {
                // This button is used to open Marketo-based PDFs in new tabs:
                window.open(url, "_blank", "noopener,noreferrer");
              }}
            >
              {icon ? (
                <Icon
                  color={"inherit"}
                  css={{ marginRight: "10px" }}
                  size={"14px"}
                  source={icon}
                  yPos={"1px"}
                />
              ) : null}
              <Text
                as={"span"}
                color={"inherit"}
                size={"small"}
                weight={"medium"}
              >
                {label}
              </Text>
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Summary;
