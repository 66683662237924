import type { Section as ContentProps } from "@10x/cms/definitions/blocks/section";
import { Fragment } from "react";
import { v4 as uuidv4 } from "uuid";

import type { Props as ContainerProps } from "./Layout/Container";
import Container from "./Layout/Container";
import { getContent } from "./utils";

interface Props {
  container: ContainerProps;
  content: ContentProps[];
}

const Section = ({ content, container }: Props) => {
  if (container) {
    container.maxWidth = container.maxWidth || "1200px";
  }
  return (
    <Container {...container}>
      {content.map((item) => {
        return <Fragment key={uuidv4()}>{getContent(item)}</Fragment>;
      })}
    </Container>
  );
};

export default Section;
