import { mediaDesktop, mediaTabletLandscape } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { ReactNode } from "react";
import { Children, useEffect, useState } from "react";

import Button from "../../Button";
import Icon from "../../Icon";

interface Props {
  active?: string;
  aspectRatio: number;
  children: ReactNode;
  gap?: number;
  itemHeight: number;
  itemsPerScroll?: number;
}

const Carousel = ({
  active,
  aspectRatio = 1.1,
  children,
  gap = 16,
  itemsPerScroll = 3,
  itemHeight = 312,
}: Props) => {
  const itemWidth = Math.round(itemHeight * aspectRatio);
  const viewWidth = (itemWidth + gap) * itemsPerScroll;
  const carouselWidth = (itemWidth + gap) * Children.toArray(children).length;

  const [offSet, setOffSet] = useState(0);

  const handlePreviousClick = () => {
    setOffSet(offSet + viewWidth);
  };

  const handleNextClick = () => {
    setOffSet(offSet - viewWidth);
  };

  useEffect(() => {
    setOffSet(0);
  }, [active]);

  return (
    <div
      className={"Carousel"}
      css={css`
        overflow-x: hidden;

        @media (min-width: ${mediaDesktop}) {
          padding-left: 0;
          padding-right: 0;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          overflow-x: scroll;
          width: 100%;
          gap: ${gap}px;

          @media (min-width: ${mediaTabletLandscape}) {
            flex-direction: row;
            min-height: ${itemHeight}px;
            overflow: visible;
            padding-bottom: 0;
            transform: translateX(${offSet}px);
            transition: transform 0.4s cubic-bezier(0.77, 0.17, 0.21, 0.81);
          }
        `}
      >
        {children}
      </div>
      <div
        css={css`
          display: none;
          padding-top: 2rem;
          @media (min-width: ${mediaTabletLandscape}) {
            display: flex;
            justify-content: flex-end;
            padding-top: 3rem;
            transform: translateX(-20vw);
          }
        `}
      >
        <Button
          background={"white"}
          border={true}
          color={"blue"}
          css={{ borderRadius: "50%", marginRight: "1rem", width: "3rem" }}
          disabled={offSet >= 0}
          onClick={handlePreviousClick}
        >
          <Icon color={"inherit"} size={"1rem"} source={"nav-left"} />
        </Button>
        <Button
          background={"white"}
          border={true}
          color={"blue"}
          css={{
            borderRadius: "50%",
            width: "3rem",
          }}
          disabled={offSet + carouselWidth < 1200}
          onClick={handleNextClick}
        >
          <Icon size={"1rem"} source={"nav-right"} />
        </Button>
      </div>
    </div>
  );
};

export default Carousel;
