import type { LinkItem } from "@10x/cms/types";
import StaticPropsContext from "@10x/site/contexts/static-props";
import {
  borderStandard,
  layoutWidth1200,
  mediaTabletLandscape,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import { useContext } from "react";

import Content from "./SignupContent";
import Form from "./SignupForm";
import type { Services } from "./types";

interface Props {
  description?: string;
  imageUrl?: string;
  links?: {
    list?: Array<LinkItem>;
    title?: string;
  };
  services?: Services;
}

const ServiceProviderSignup: FunctionComponent<Props> = ({
  description,
  imageUrl,
  links,
  services,
}) => {
  const { form, passedValues } = useContext(StaticPropsContext);

  return (
    <div
      css={css`
        padding: 4rem 1rem;
        max-width: ${layoutWidth1200};
        width: 100%;

        @media (min-width: ${mediaTabletLandscape}) {
          display: flex;
        }
      `}
    >
      <div
        css={css`
          box-sizing: border-box;
          min-height: 800px;

          @media (min-width: ${mediaTabletLandscape}) {
            border-right: ${borderStandard};
            flex-basis: 40%;
            padding-right: 10%;
          }
        `}
      >
        {form && <Form passedValues={passedValues} steps={form.steps} />}
      </div>
      <div
        css={css`
          box-sizing: border-box;
          margin-bottom: 3rem;

          @media (min-width: ${mediaTabletLandscape}) {
            flex-basis: 60%;
            min-width: 600px;
            padding-left: 10%;
          }
        `}
      >
        <Content
          description={description}
          links={links}
          logo={imageUrl}
          services={services}
        />
      </div>
    </div>
  );
};

export default ServiceProviderSignup;
