import type { TextSize, TextWeight } from "@10x/types";
import { mediaPhoneOnly } from "@10xdev/design-tokens";
import type { SerializedStyles } from "@emotion/react";
import { css } from "@emotion/react";
import useMutationObserver from "@rooks/use-mutation-observer";
import classnames from "classnames";
import type { FunctionComponent, ReactNode } from "react";
import { useCallback, useEffect, useRef, useState } from "react";

import Text from "../Text";
import AccordionToggle from "./AccordionToggle";

interface Props {
  activeInitial?: boolean;
  children: ReactNode;
  className?: string;
  headingAttrs?: {
    size?: TextSize;
    title?: string;
    toggleSize?: "small" | "medium" | "large";
    weight?: TextWeight;
  };
  styles?: {
    contents?: SerializedStyles;
    heading?: SerializedStyles;
  };
}

const headingCss = css`
  align-items: baseline;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  outline: none;
  padding: 0;
  margin-bottom: 0;
  text-align: left;
`;

const contentsCss = css`
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(0.46, 0.01, 0.92, 0.77);

  @media (max-width: ${mediaPhoneOnly}) {
    padding-right: 1.5rem;
  }
`;

const Accordion: FunctionComponent<Props> = ({
  activeInitial = false,
  className,
  children,
  headingAttrs,
  styles = {},
}) => {
  const [active, setActive] = useState<boolean>(false);
  const [height, setHeight] = useState("0px");
  const contentRef = useRef<HTMLDivElement>(null);

  const { heading, contents } = styles;
  const {
    size = "medium",
    weight = "semibold",
    toggleSize = "medium",
    title,
  } = headingAttrs || {};

  // Open the accordion if a country is selected
  if (
    activeInitial &&
    typeof active === "undefined" &&
    active !== activeInitial
  ) {
    setActive(activeInitial);
  }

  // We want to recalculate the height whenever the children change
  // Can't really do a pure css solution: https://css-tricks.com/using-css-transitions-auto-dimensions/
  const reflow = () => {
    const contentHeight = contentRef.current?.scrollHeight;
    setHeight(active ? `${contentHeight}px` : "0px");
  };

  const callbackedReflow = useCallback(reflow, [active]);

  useEffect(callbackedReflow, [callbackedReflow]);

  useMutationObserver(contentRef, reflow);

  function toggleAccordion() {
    setActive((prevActive) => !prevActive);
  }

  return (
    <div
      className={classnames(className, {
        active: active,
      })}
      css={css`
        display: flex;
        flex-direction: column;
        overflow: hidden;
      `}
    >
      <button
        aria-expanded={active}
        css={[headingCss, heading]}
        onClick={toggleAccordion}
      >
        <Text
          as={"h4"}
          css={css`
            margin-bottom: 0;
            max-width: 90%;
          `}
          responsive={true}
          size={size}
          weight={weight}
        >
          {title}
        </Text>
        <AccordionToggle active={active} size={toggleSize} />
      </button>

      <div
        css={[
          contentsCss,
          contents,
          css`
            max-height: ${height};
          `,
        ]}
        ref={contentRef}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
