import type { LinkItem } from "@10x/cms/types";
import {
  mediaTabletLandscape,
  mediaTabletPortrait,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import { ListCard } from "../../Cards";
import Heading from "../../Heading";
import Text from "../../Text";
import LinkList from "./LinkList";
import ListItem from "./ListItem";

interface Props {
  primaryLinks?: Array<{
    description?: string;
    linkList?: Array<{
      description?: string;
      footnote?: string;
      link?: LinkItem;
      title?: string;
    }>;
    title?: string;
  }>;
  secondaryLinks?: {
    linkList: Array<LinkItem>;
    title: string;
  };
}

const LinkGroup: FunctionComponent<Props> = ({
  primaryLinks,
  secondaryLinks,
}) => {
  return (
    <div
      css={css`
        max-width: 1200px;
        padding: 2.5rem 2rem 4.5rem;
        width: 100%;

        @media (min-width: ${mediaTabletLandscape}) {
          display: grid;
          padding-top: 4rem;
          grid-template-columns: 4fr 1fr;
        }
      `}
    >
      <div
        css={css`
          @media (min-width: ${mediaTabletLandscape}) {
            box-sizing: border-box;
          }
        `}
      >
        {primaryLinks?.length
          ? primaryLinks.map((group) => {
              const { title, description, linkList } = group;
              return (
                <div
                  css={css`
                    margin-bottom: 3.5rem;

                    @media (min-width: ${mediaTabletPortrait}) {
                      display: grid;
                      grid-template-columns: 3fr 5fr;
                    }

                    @media (min-width: ${mediaTabletLandscape}) {
                      margin-bottom: 4.5rem;
                    }
                  `}
                  key={title}
                >
                  <div
                    css={css`
                      margin-bottom: 1.5rem;

                      @media (min-width: ${mediaTabletPortrait}) {
                        box-sizing: border-box;
                        padding-right: 4.5rem;
                      }
                    `}
                  >
                    <Heading
                      as={"h3"}
                      css={css`
                        margin-bottom: 1.5rem;
                      `}
                      responsive={true}
                      size={"xxlarge"}
                    >
                      {title}
                    </Heading>
                    <Text
                      as={"div"}
                      color={"midgray"}
                      responsive={true}
                      size={"medium"}
                    >
                      {description}
                    </Text>
                  </div>

                  <div>
                    {linkList?.length ? (
                      <ListCard>
                        {linkList.map((item) => {
                          return <ListItem item={item} key={item.link?.url} />;
                        })}
                      </ListCard>
                    ) : null}
                  </div>
                </div>
              );
            })
          : null}
      </div>

      {secondaryLinks?.linkList?.length ? (
        <div
          css={css`
            @media (min-width: ${mediaTabletLandscape}) {
              justify-self: end;
            }
          `}
        >
          <LinkList
            linkList={secondaryLinks.linkList}
            title={secondaryLinks.title}
          />
        </div>
      ) : null}
    </div>
  );
};

LinkGroup.defaultProps = {};

export default LinkGroup;
