import type { TextSize } from "@10x/types";
import type { Navigation_FullFragment } from "@10x/types/__generated__/graphql-types";
import {
  colorGrayLightest,
  colorGreyscale7,
  colorSteelDarkest,
  colorWhite,
  layoutWidth1200,
  radialGradientBlue,
} from "@10xdev/design-tokens/dist";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Heading from "../../Heading";
import Icon from "../../Icon";
import type { IconSource } from "../../Icon/types";
import Text from "../../Text/Text";
import Banner from "../Masthead/Banner";
import type { BackgroundColor, BackgroundMode } from "../Masthead/types";

interface Props {
  background?: BackgroundColor;
  headline: string;
  headlineSize?: TextSize;
  mode?: BackgroundMode;
  navigation: Navigation_FullFragment;
  padding?: string;
  subheadline?: string | null;
  subheadlineLink?: {
    href: string;
    icon?: IconSource;
    label: string;
  };
}

const backgrounds: Record<BackgroundColor, string> = {
  base: colorSteelDarkest,
  black: colorGreyscale7,
  blue: radialGradientBlue,
  gray: colorGrayLightest,
  white: colorWhite,
};

const PageTitle: FunctionComponent<Props> = ({
  background = "base",
  mode = "dark",
  headline,
  headlineSize = "xxlarge",
  navigation,
  padding = "1rem 0 3rem",
  subheadline,
  subheadlineLink,
}) => {
  const color = mode === "dark" ? "white" : "base";
  return (
    <div
      className={"PageTitle"}
      css={css`
        align-items: center;
        background-color: ${backgrounds[background]};
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        padding: 0 1rem;
        width: 100%;
      `}
    >
      <Banner mode={mode} navigation={navigation} />
      <div
        css={css`
          max-width: ${layoutWidth1200};
          padding: ${padding};
          width: 100%;
        `}
      >
        {headline ? (
          <Heading
            as={"h2"}
            color={color}
            css={css`
              margin-bottom: ${subheadline ? "1rem" : 0};
            `}
            size={headlineSize}
            weight={"semibold"}
          >
            {headline}
          </Heading>
        ) : null}

        {subheadline ? (
          <Text
            as={"span"}
            color={"midgray"}
            css={css`
              margin-bottom: 0;
            `}
            responsive={true}
            size={"medium"}
          >
            {`${subheadline} `}
          </Text>
        ) : null}
        {subheadlineLink ? (
          <Anchor
            css={css`
              align-items: center;
              display: inline-flex;
              gap: 0.75rem;
            `}
            href={subheadlineLink.href}
            key={subheadlineLink.href}
          >
            <Text
              as={"span"}
              color={"inherit"}
              responsive={true}
              size={"medium"}
            >
              {subheadlineLink.label}
            </Text>
            {subheadlineLink.icon ? (
              <Icon
                color={"inherit"}
                size={"11px"}
                source={subheadlineLink.icon}
                yPos={"1px"}
              />
            ) : null}
          </Anchor>
        ) : null}
      </div>
    </div>
  );
};

export default PageTitle;
