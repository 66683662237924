import { css } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";

import Text from "../Text";

interface Props {
  children?: ReactNode;
  className?: string;
}

const CellStyle = css`
  padding: 1.5rem;
  vertical-align: top;
`;

const Cell: FunctionComponent<Props> = ({ children, className }) => {
  return (
    <Text as={"td"} className={className} css={CellStyle} size={"small"}>
      {children}
    </Text>
  );
};

export default Cell;
