import type { EnrichedRelatedProductBlock } from "@10x/cms/utils/get-blocks";
import {
  borderStandard,
  layoutWidth878,
  layoutWidth1200,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Cell from "../../ComparisonTable/Cell";
import RowHead from "../../ComparisonTable/RowHead";
import Table from "../../ComparisonTable/Table";
import TableBody from "../../ComparisonTable/TableBody";
import TableHead from "../../ComparisonTable/TableHead";
import Paragraph from "../../Paragraph";
import ProductAttribute from "./ProductAttribute";
import ProductHeading from "./ProductHeading";
import SectionHeading from "./SectionHeading";

const RelatedProductsTable: FunctionComponent<EnrichedRelatedProductBlock> = ({
  allProductsLink,
  attributes,
  footnote,
  maxItems,
  products,
  productsLabel,
  title,
}) => {
  if (!products || !products.length) {
    return null;
  }

  // Limit number of products to value defined in maxItems.
  // Should be 4
  const allowedProducts = products.slice(
    0,
    (maxItems && parseInt(maxItems)) || products.length,
  );

  const productHeadings = allowedProducts.map((product) => {
    return {
      name: product.name,
      slug: product.slug,
    };
  });

  return (
    <div
      className={"RelatedProducts"}
      css={css`
        max-width: ${layoutWidth1200};
        padding: 4rem 1rem;

        & tr:not(:last-of-type) {
          border-bottom: ${borderStandard};
        }

        @media (max-width: ${layoutWidth878}) {
          margin-bottom: 6rem;
        }
      `}
    >
      <SectionHeading link={allProductsLink} title={title} />

      <Table columns={allowedProducts.length}>
        <TableHead>
          <RowHead label={productsLabel} />

          {productHeadings.map((product) => {
            return <ProductHeading key={product.slug} product={product} />;
          })}
        </TableHead>

        <TableBody>
          {(attributes || []).map(({ label, attribute }) => {
            return (
              <tr key={label}>
                <RowHead label={label} />

                {attribute &&
                  allowedProducts.map((product) => {
                    const productAttributeValue =
                      product[attribute as keyof typeof product];

                    if (!productAttributeValue) {
                      return null;
                    }

                    if (!Array.isArray(productAttributeValue)) {
                      throw new Error(`${attribute} must be an array`);
                    }

                    const values = productAttributeValue.map((value) => {
                      return {
                        label: "label" in value ? value.label : value.name,
                        url: "url" in value ? value.url : null,
                      };
                    });

                    return (
                      productAttributeValue && (
                        <Cell key={`${product.name}`}>
                          <ProductAttribute list={values} />
                        </Cell>
                      )
                    );
                  })}
              </tr>
            );
          })}
        </TableBody>
      </Table>

      {footnote ? <Paragraph size={"medium"}>{footnote}</Paragraph> : null}
    </div>
  );
};

export default RelatedProductsTable;
