import type { LinkItem } from "@10x/cms/types";
import {
  borderStandard,
  colorBlueDark,
  colorBlueMedium,
  layoutWidth1200,
  mediaTabletLandscape,
  mediaTabletPortrait,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Heading from "../../Heading";
import Icon from "../../Icon";
import Paragraph from "../../Paragraph";
import Text from "../../Text";

interface Props {
  panels: Array<{
    description?: string;
    itemList: Array<{
      link: {
        linkText: string;
        url: string;
      };
      text?: string;
    }>;
    linkList?: Array<LinkItem>;
    title: string;
  }>;
  slash?: boolean;
  subtitle: string;
  title: string;
}

const ListPanels: FunctionComponent<Props> = ({
  panels,
  slash = true,
  subtitle,
  title,
}) => {
  return (
    <div
      className={"RequirementsPanels"}
      css={css`
        box-sizing: border-box;
        max-width: ${layoutWidth1200};
        width: 100%;
        padding: 5rem 1rem;
        margin: 0 auto;

        ::before {
          border-top: ${slash ? "1px" : 0} solid ${colorBlueMedium};
          content: "";
          position: absolute;
          transform: translate(-48px, 16px) rotate(120deg);
          transform-origin: 0% 0%;
          width: 300px;
        }

        @media (max-width: ${mediaTabletLandscape}) {
            padding: 5rem 1rem;
      `}
    >
      <Heading
        as={"h3"}
        css={css`
          margin-bottom: ${subtitle ? "2rem" : "4rem"};
        `}
        responsive={true}
        size={"xxxlarge"}
      >
        {title}
      </Heading>

      {subtitle ? (
        <Text
          as={"div"}
          color={"midgray"}
          css={css`
            margin-bottom: 4rem;
          `}
          responsive={true}
          size={"medium"}
          weight={"regular"}
        >
          {subtitle}
        </Text>
      ) : null}

      <div
        css={css`
          box-sizing: border-box;
          display: flex;

          @media (max-width: ${mediaTabletLandscape}) {
            display: block;
            padding-right: 25%;
          }

          @media (max-width: ${mediaTabletPortrait}) {
            padding-right: 0;
          }
        `}
      >
        {panels && panels.length
          ? panels.map((panel, index) => {
              const { description, itemList, linkList, title } = panel;

              return (
                <div
                  css={css`
                    border-right: ${borderStandard};
                    display: flex;
                    flex-direction: column;
                    padding-left: 5.5%;
                    padding-right: 5.5%;
                    width: 33%;

                    :first-of-type {
                      padding-left: 0;
                    }

                    :last-of-type {
                      border-right: none;
                      padding-right: 0;
                    }

                    @media (max-width: ${mediaTabletLandscape}) {
                      border: 0;
                      border-bottom: ${borderStandard};
                      margin-bottom: 3rem;
                      padding: 0 0 2rem;
                      width: 100%;
                    }
                  `}
                  key={index}
                >
                  <Heading as={"h4"} size={"large"}>
                    {title}
                  </Heading>

                  {description ? (
                    <Paragraph
                      color={"midgray"}
                      key={description}
                      responsive={true}
                      size={"medium"}
                    >
                      {description}
                    </Paragraph>
                  ) : null}

                  {itemList ? (
                    <ul
                      css={css`
                        display: flex;
                        flex-direction: column;
                        gap: 0.75rem;
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        margin-bottom: 2rem;
                      `}
                    >
                      {itemList.map((item) => {
                        const { link, text } = item;
                        return (
                          <li key={text || link.linkText}>
                            <Text as={"span"} color={"midgray"} size={"small"}>
                              {link ? (
                                <Anchor
                                  aria-label={`${link.linkText} for ${title}`}
                                  href={link.url}
                                >
                                  {link.linkText}{" "}
                                </Anchor>
                              ) : null}
                              {text ? text : null}
                            </Text>
                          </li>
                        );
                      })}
                    </ul>
                  ) : null}

                  {linkList ? (
                    <ul
                      css={css`
                        display: flex;
                        flex-direction: column;
                        gap: 0.75rem;
                        list-style: none;
                        margin: 0;
                        padding: 0;
                      `}
                    >
                      {linkList.map((link) => {
                        const { label, type, url } = link;
                        return (
                          <>
                            {url ? (
                              <li key={url}>
                                <Text
                                  as={"span"}
                                  color={"blue"}
                                  size={"small"}
                                  weight={"semibold"}
                                >
                                  <Anchor
                                    color={"inherit"}
                                    hoverColor={colorBlueDark}
                                    href={url}
                                  >
                                    {label}
                                    {type ? (
                                      <Icon
                                        size={"12px"}
                                        source={type}
                                        xPos={"16px"}
                                        yPos={"1px"}
                                      />
                                    ) : null}
                                  </Anchor>
                                </Text>
                              </li>
                            ) : null}
                          </>
                        );
                      })}
                    </ul>
                  ) : null}
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default ListPanels;
